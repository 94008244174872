import './Header.css'

function Header() {
    return (
        <header>
            <h1>Letter Engine</h1>
            <h3>anchor@letterengine.xyz</h3>
        </header>
    );
}

export default Header;