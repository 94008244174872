import './Footer.css';
import { useState, useEffect } from 'react';

function getHour(currentHour = new Date()) {
    const hours = String(currentHour.getHours()).padStart(2, '0'),
          minutes = String(currentHour.getMinutes()).padStart(2, '0'),
          seconds = String(currentHour.getSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
}

function getDate(lang, currentDate = new Date()) {
    const year = currentDate.getFullYear(),
          month = String(currentDate.getMonth()+1).padStart(2, '0'),
          day = String(currentDate.getDate()).padStart(2, '0'),
          sep = '·';
    const engDate = `${month}${sep}${day}${sep}${year}`,
          esDate = `${day}${sep}${month}${sep}${year}`;
    return lang === 'en' ? engDate : esDate;
}

function Footer (props) {
    // Footer state
    const [now, setNow] = useState('00:00:00');
    // Set footers states
    useEffect(() => {
        const myHour = setTimeout(() => {
          setNow(getHour());
        }, 1000);
        return() => clearTimeout(myHour);
    }, [now]);
    // Return footer
    return (
        <footer>
            <p>Letter Engine ©</p>
            <p>{props.lang === 'en' ? 'All Rights Reserved' : 'Todos los derechos reservados'}</p>
            <p>{`${getDate(props.lang)} ${now}`}</p>
        </footer>
    )
}

export default Footer;