import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { useState, useEffect } from 'react';
// Components
import Footer from '../Footer/Footer';
import Header from '../Header/Header';

function App() {
  // Define states
  const [lang, setLanguage] = useState('en');
  // Set states
  useEffect(() => {
    setLanguage(document.documentElement.lang.includes('es') ? 'es' : 'en');
  }, [])
  // Return App
  return (
    <Router>
      <div className="App">
        <Header />
        <Footer lang={lang} />
      </div>
    </Router>
  );
}

export default App;
